html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
    display: block;
}

svg:not(:root) {
    overflow: hidden;
}

hr {
    height: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

pre {
    overflow: auto;
}

code,kbd,pre,samp {
    font-family: monospace,monospace;
    font-size: 1em;
}

button,input,optgroup,select,textarea {
    margin: 0;
    font: inherit;
    color: inherit;
}

button {
    overflow: visible;
}

button,select {
    text-transform: none
}

button,html input[type=button],input[type=reset],input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

input {
    line-height: normal;
}

input[type=checkbox],input[type=radio] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
    height: auto;
}

textarea {
    overflow: auto;
}

table {
    border-spacing: 0;
    border-collapse: collapse
}

td,th {
    padding: 0
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

:after,:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff
}

button,input,select,textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

a {
    color: #337ab7;
    text-decoration: none
}

a:focus,a:hover {
    color: #23527c;
    text-decoration: underline
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    font-family: monospace;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

.h1,.h2,.h3,h1,h2,h3 {
    margin-top: 20px;
    margin-bottom: 10px
}

.h4,.h5,.h6,h4,h5,h6 {
    margin-top: 10px;
    margin-bottom: 10px
}

.h2,h2 {
    font-size: 30px
}

.h3,h3 {
    font-size: 24px
}

.h4,h4 {
    font-size: 18px
}

h5, .h5 {
    font-size: 16px;
}

p {
    margin: 0 0 10px
}

.text-justify {
    text-align: justify
}

ol,ul {
    margin-top: 0;
    margin-bottom: 10px
}

code,kbd,pre,samp {
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace
}

pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    border: 1px solid #ccc;
    border-radius: 4px
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 768px) {
    .container {
        width:750px
    }
}

@media (min-width: 992px) {
    .container {
        width:970px
    }
}

@media (min-width: 1200px) {
    .container {
        width:1170px
    }
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px
}

.table>tbody>tr>td,.table>tbody>tr>th,.table>tfoot>tr>td,.table>tfoot>tr>th,.table>thead>tr>td,.table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700
}

input[type=checkbox],input[type=radio] {
    font-family: monospace;
    margin: 4px 0 0;
    margin-top: 1px\9;
    line-height: normal
}

input[type=file]:focus,input[type=checkbox]:focus,input[type=radio]:focus {
    font-family: monospace;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
}

.form-control::-webkit-input-placeholder {
    font-family: monospace;
    color: #999
}

.checkbox,.radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px
}

.checkbox label,.radio label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer
}

.checkbox input[type=checkbox],.checkbox-inline input[type=checkbox],.radio input[type=radio],.radio-inline input[type=radio] {
    position: absolute;
    margin-top: 4px\9;
    margin-left: -20px
}

.checkbox+.checkbox,.radio+.radio {
    margin-top: -5px
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0px
}

.btn.active.focus,.btn.active:focus,.btn.focus,.btn:active.focus,.btn:active:focus,.btn:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.btn.focus,.btn:focus,.btn:hover {
    color: #333;
    text-decoration: none
}

.btn.active,.btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125)
}

.btn-group-sm>.btn,.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 0px
}

.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.fade.show {
    opacity: 1
}

.collapse {
    display: none
}

.collapse.show {
    display: block
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .35s;
    -o-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-property: height,visibility;
    -o-transition-property: height,visibility;
    transition-property: height,visibility
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0
}

.input-group .form-control:focus {
    z-index: 3
}

.input-group .form-control,.input-group-addon,.input-group-btn {
    display: table-cell
}

.input-group-addon,.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle
}

.input-group .form-control:first-child,.input-group-addon:first-child,.input-group-btn:first-child>.btn,.input-group-btn:first-child>.btn-group>.btn,.input-group-btn:first-child>.dropdown-toggle,.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group .form-control:last-child,.input-group-addon:last-child,.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,.input-group-btn:first-child>.btn:not(:first-child),.input-group-btn:last-child>.btn,.input-group-btn:last-child>.btn-group>.btn,.input-group-btn:last-child>.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap
}

.input-group-btn>.btn {
    position: relative
}

.input-group-btn>.btn:active,.input-group-btn>.btn:focus,.input-group-btn>.btn:hover {
    z-index: 2
}

.input-group-btn:last-child>.btn,.input-group-btn:last-child>.btn-group {
    z-index: 2;
    margin-left: -1px
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav>li {
    position: relative;
    display: block
}

.nav-link2 {
    position: relative;
    display: block;
    padding: 10px 15px
}

.nav-link2:focus,.nav-link2:hover {
    text-decoration: none;
    background-color: #eee
}

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 3px;
    border: 1px solid transparent;
    font-family: monospace;
}

@media (min-width: 768px) {
    .navbar-header {
        float:left
    }
}

.navbar-collapse {
    border-top: 1px solid transparent;
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
}

.navbar-collapse.show {
    overflow-y: auto
}

@media (min-width: 768px) {
    .navbar-collapse {
        width:auto;
        border-top: 0;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .navbar-collapse.collapse {
        display: block!important;
        height: auto!important;
        padding-bottom: 0;
        overflow: visible!important
    }

    .navbar-collapse.show {
        overflow-y: visible
    }

    .fixed-bottom .navbar-collapse,.fixed-top .navbar-collapse,.navbar-static-top .navbar-collapse {
        padding-right: 0;
        padding-left: 0
    }
}

.fixed-bottom .navbar-collapse,.fixed-top .navbar-collapse {
    max-height: 340px
}

@media (max-device-width: 480px) and (orientation:landscape) {
    .fixed-bottom .navbar-collapse,.fixed-top .navbar-collapse {
        max-height:200px
    }
}

.container-fluid>.navbar-collapse,.container-fluid>.navbar-header,.container>.navbar-collapse,.container>.navbar-header {
    margin-right: -15px;
    margin-left: -15px
}

@media (min-width: 768px) {
    .container-fluid>.navbar-collapse,.container-fluid>.navbar-header,.container>.navbar-collapse,.container>.navbar-header {
        margin-right:0;
        margin-left: 0
    }
}

.fixed-bottom,.fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-top {
    top: 0;
    border-width: 0 0 1px
}

.navbar-brand:focus,.navbar-brand:hover {
    text-decoration: none
}

@media (min-width: 768px) {
    .navbar>.container .navbar-brand,.navbar>.container-fluid .navbar-brand {
        margin-left:-15px
    }
}

.navbar-toggler {
    float: right;
    padding: 14px;
    background-color: transparent;
    background-image: none;
    border: 0px solid transparent;
}

.navbar-toggler:focus {
    outline: 0
}

.navbar-toggler-icon {
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 22px;
    height: 22px;
    border-radius: 0px
}

.navbar-toggler-icon+.icon {
    margin-top: 4px
}

@media (min-width: 768px) {
    .navbar-toggler {
        display:none
    }
}

.navbar-nav, .navbar-right {
    margin: 5px -15px
}

.nav-link2 {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px
}

@media (min-width: 768px) {
    .navbar-nav {
        float:left;
        margin: 0
    }

    .navbar-right {
        float:right;
        margin: 0
    }

    .nav-link2 {
        float: left;
        padding-top: 15px;
        padding-bottom: 15px
    }
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}

.alert>p,.alert>ul {
    margin-bottom: 0
}

.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0,-25%);
    -ms-transform: translate(0,-25%);
    -o-transform: translate(0,-25%);
    transform: translate(0,-25%)
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0)
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
}

.modal-content {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0px;
    outline: 0;
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5
}

.modal-title {
    margin: 0;
    line-height: 1.42857143
}

.modal-body {
    position: relative;
    padding: 15px
}

.modal-footer {
    font-family: monospace;
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5
}

.modal-footer .btn+.btn {
    font-family: monospace;
    margin-bottom: 0;
    margin-left: 5px;
}

@media (min-width: 768px) {
    .modal-dialog {
        width:600px;
        margin: 30px auto
    }

    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
        box-shadow: 0 5px 15px rgba(0,0,0,.5)
    }

    .modal-sm {
        width: 300px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    filter: alpha(opacity=0);
    opacity: 0;
    line-break: auto;
    font-family: monospace;
}

.tooltip.show {
    filter: alpha(opacity=90);
    opacity: .9
}

.tooltip.right {
    padding: 0 5px;
    margin-left: 3px
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    text-align: center;
    border-radius: 0px
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000
}

.btn-group-vertical>.btn-group:after,.btn-group-vertical>.btn-group:before,.btn-toolbar:after,.btn-toolbar:before,.clearfix:after,.clearfix:before,.container-fluid:after,.container-fluid:before,.container:after,.container:before,.dl-horizontal dd:after,.dl-horizontal dd:before,.form-horizontal .form-group:after,.form-horizontal .form-group:before,.modal-footer:after,.modal-footer:before,.modal-header:after,.modal-header:before,.nav:after,.nav:before,.navbar-collapse:after,.navbar-collapse:before,.navbar-header:after,.navbar-header:before,.navbar:after,.navbar:before,.pager:after,.pager:before,.panel-body:after,.panel-body:before,.row:after,.row:before {
    display: table;
    content: " "
}

.btn-group-vertical>.btn-group:after,.btn-toolbar:after,.clearfix:after,.container-fluid:after,.container:after,.dl-horizontal dd:after,.form-horizontal .form-group:after,.modal-footer:after,.modal-header:after,.nav:after,.navbar-collapse:after,.navbar-header:after,.navbar:after,.pager:after,.panel-body:after,.row:after {
    clear: both
}

body.fullscreen>div>.rootPage {
    height: calc(100vh - 3px);
}

.rootPage {
    height: stretch;
    min-height: calc(100vh - 55px);
    flex-direction: column;
    display: flex;
}

.flexcomponent {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.flexy {
    flex: 1 0 auto;
}

.miniSpacer {
    display: inline-block;
    margin-left: 0.4em;
}

.footer {
    font-family: monospace;
    font-size: small;
    padding-right: 10px;
    text-align: right;
}

body {
    margin: 0;
    padding-top: 55px;
}

body.fullscreen {
    padding-top: 3px;
    height: stretch;
    min-height: calc(100vh - 3px);
}

body.fullscreen .footer {
    display: none;
}

body.fullscreen nav {
    display: none;
}

.form-control, .btn-group-sm>.btn, .btn-sm, .btn-default, .btn, .alert,
.modal-content, .navbar-toggler {
    border-radius: 0;
}

input {
    font-family: monospace;
    border: 1px solid;
}

input[type=checkbox] {
    -webkit-appearance: none;
    border: 1px solid;
    box-shadow: none;
    padding: 5px;
    border-radius: 0px;
    display: inline-block;
    position: relative;
}
input[type=checkbox]:checked:after {
    content: 'on';
    font-size: 10px;
    position: absolute;
    top: -1px;
    left: -1px;
}

.table {
    margin-bottom: 0;
}

.table .table {
    background-color: unset;
}

.table-hover>tbody>tr.no-hover {
    cursor: default
}

.table-hover>tbody>tr.no-hover:hover {
    color: unset;
    background-color: unset;
    cursor: default
}

.table>tbody>tr>td {
    vertical-align: middle;
}

.modal-header {
    border-bottom: none;
    padding-bottom: 0;
}

.modal-footer {
    padding: 0;
    margin-right: -.5px;
    margin-bottom: -.5px;
    border-top: none;
}

.modal-content {
    box-shadow: none;
}

a {
    background-color: transparent;
    font-weight: bold;
    font-family: monospace;
    white-space: nowrap;
}

a:active,a:hover {
    outline: 0;
}

.footer a {
    font-weight: normal;
}

strong {
    font-weight: 700;
    font-family: monospace;
}

b {
    font-weight: 700;
}

.tooltip.right .tooltip-arrow {
    visibility: hidden;
}

.tooltip.right {
    margin: 0;
}

.tooltip-inner {
    max-width: none;
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    font-size: 13px;
}

.buttonSimul {
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    margin: -1px 0 0 0;
    padding: 1px 3px 0px 4px;
    display: inline-block;
    font-family: monospace;
}

.glyphicon {
    position: relative;
    top: 2px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.btn-sm .glyphicon {
    top: 1px;
}

.table>tbody>tr>td .glyphicon,
.table>tbody>tr>th .glyphicon,
.table>tfoot>tr>td .glyphicon,
.table>tfoot>tr>th .glyphicon,
.table>thead>tr>td .glyphicon,
.table>thead>tr>th .glyphicon {
    top: 1px;
}

.glyphicon-repeat:before {
    content: "\e030";
}

.glyphicon-pencil:before {
    content: "\270f";
}

.glyphicon-cog:before {
    content: "\e019";
}

.glyphicon-trash:before {
    content: "\e020";
}

.glyphicon-file:before {
    content: "\e022";
}

.glyphicon-download-alt:before {
    content: "\e025";
}

.glyphicon-folder-close:before {
    content: "\e117";
}

.glyphicon-folder-open:before {
    content: "\e118";
}

.glyphicon-ok-sign:before {
    content: "\e084";
}

.glyphicon-lock:before {
    content: "\e033";
}

.glyphicon-link:before {
    content: "\e144";
}

.glyphicon-copy:before {
    content: "\e205";
}

.glyphicon-duplicate:before {
    content: "\e224";
}

.glyphicon-upload:before {
    content: "\e198";
}

.glyphicon-download:before {
    content: "\e197";
}

.glyphicon-search:before {
    content: "\e003";
}

.glyphicon-exclamation-sign:before {
    content: "\e101";
}

.glyphicon-menu-hamburger:before {
    content: "\e236";
    padding: 2px 0;
}

.cm-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    position: relative;
    top: -1px;

    display: inline;
    padding: 0 .4em .1em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: center;
    border-radius: 0;
    border: 1px solid;
}

.qr {
    width: 60%;
    margin: 0 auto;
}

.splitter-layout {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
    overflow: auto;
}

.splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
}

.splitter-layout > .layout-splitter {
    flex: 0 0 auto;
    width: 4px;
    height: 100%;
    cursor: col-resize;
}

.splitter-layout.layout-changing {
    cursor: col-resize;
}

.splitter-layout.splitter-layout-vertical {
    flex-direction: column-reverse;
}

.splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 4px;
    cursor: row-resize;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 155px;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid;
}

.card > hr {
    margin-right: 0;
    margin-left: 0;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 155px) {
    .card-deck {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(170px, 1fr));
        margin-right: -7.5px;
        margin-left: -7.5px;
    }
    .card-deck .card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-right: 7.5px;
        margin-bottom: 15px;
        margin-left: 7.5px;
    }
}


