.playground {
    flex: 1;
    overflow-y: hidden;
    padding-bottom: 3px;

    position: relative;
    width: calc(100% - 6px);
    margin-left: 3px;
}

.playground > div {
    position: absolute;
    left: 0px;
    height: calc(100% - 3px);
    width: 100%;
}

.playground .window-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.rootPage {
    max-height: 100vh;
}

.headerButtons {
    display: flex;
    float: right;
}

.headerButtons input,
.headerButtons button {
    margin-top: -.5px;
    height: 30px;
}

.headerButtons input {
    margin-right: -1px;
}

pre {
    background-color: transparent;
}

.pre-reset {
    border: 0;
    white-space: pre-wrap;
    border-radius: 0;
    margin: 0;
    padding: 0;
    word-break: break-word;
}
