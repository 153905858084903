.flexcomponent.flexy > .CodeMirror {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.flexcomponent.flexy > .CodeMirror > .CodeMirror-scroll {
    flex: 1;
}

.ReactCodeMirror {
    height: 100%;
}

.eval-success {
    background-color: rgb(210, 255, 210);
}

.eval-success-odd {
    background-color: #dcffff;
}

.eval-fail {
    background-color: rgba(255, 220, 220, 200);
}

span.CodeMirror-matchingbracket {
    color: #F4F !important;
}
