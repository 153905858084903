.mini-window {
    border: 1px solid;
    border-radius: 0;
    height: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: column;
}

.mini-collapsed > .window-header {
    flex: 1;
    word-break: break-all;
}

.mini-collapsed > .window-header :not(.window-title) {
    display: none;
}

.mini-collapsed > .window-content, .mini-collapsed > .window-footer {
    display: none !important;
}

.window-header {
    padding: 0rem .20rem;
    min-height: 32px;
}

body.fullscreen .window-header {
    display: none;
}

.window-title {
    font-family: Helvetica,sans-serif;
    font-size: 22px;
    display: inline-block;
    font-family: monospace;
}

.window-content {
    flex: 1;
}

.window-footer {
    padding: .1rem 1rem;
}

.window-footer.no-content {
    padding: 0;
}

.noselect {
    user-select: none;
}
